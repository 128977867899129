exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesibilidad-jsx": () => import("./../../../src/pages/accesibilidad.jsx" /* webpackChunkName: "component---src-pages-accesibilidad-jsx" */),
  "component---src-pages-aviso-de-privacidad-jsx": () => import("./../../../src/pages/aviso-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-nomadia-jsx": () => import("./../../../src/pages/partners/nomadia.jsx" /* webpackChunkName: "component---src-pages-partners-nomadia-jsx" */),
  "component---src-pages-partners-precisely-jsx": () => import("./../../../src/pages/partners/precisely.jsx" /* webpackChunkName: "component---src-pages-partners-precisely-jsx" */),
  "component---src-pages-partners-tomtom-jsx": () => import("./../../../src/pages/partners/tomtom.jsx" /* webpackChunkName: "component---src-pages-partners-tomtom-jsx" */),
  "component---src-pages-soluciones-geolocalizacion-jsx": () => import("./../../../src/pages/soluciones/geolocalizacion.jsx" /* webpackChunkName: "component---src-pages-soluciones-geolocalizacion-jsx" */),
  "component---src-pages-soluciones-geomarketing-jsx": () => import("./../../../src/pages/soluciones/geomarketing.jsx" /* webpackChunkName: "component---src-pages-soluciones-geomarketing-jsx" */),
  "component---src-pages-soluciones-index-jsx": () => import("./../../../src/pages/soluciones/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-index-jsx" */),
  "component---src-pages-soluciones-logistica-de-entrega-jsx": () => import("./../../../src/pages/soluciones/logistica-de-entrega.jsx" /* webpackChunkName: "component---src-pages-soluciones-logistica-de-entrega-jsx" */)
}

